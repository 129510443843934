import "./index.css";
import Swiper from "swiper";
import 'swiper/css/swiper.min.css';
import images from './img/*.png';
import moment from 'moment';
import 'moment/locale/fr';

class App {
  constructor() {
    this.nodes = null
    this.reloadTotal = 120
    this.getNbNodes()

      var win = window,
          doc = document,
          docElem = doc.documentElement,
          body = doc.getElementsByTagName('body')[0],
          x = win.innerWidth || docElem.clientWidth || body.clientWidth,
          y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
      console.log(body.clientWidth + ' × ' + body.clientHeight);
  }

  getNbNodes() {
    const urlParams = new URLSearchParams(window.location.search);
    let quoi29Url = "https://quoi2-9.groupe-telegramme.fr/api/tv?token=tv29TLG"
    // console.log(queryString)
    // console.log(urlParams.has('preview'))
    if (urlParams.has('preview')) quoi29Url += '&preview';
    if (urlParams.has('nid')) quoi29Url += '&nid=' + urlParams.get('nid');
    if (urlParams.has('geo')) {
      quoi29Url += '&geo=' + urlParams.get('geo');
    }

    fetch(quoi29Url) 
    .then(response => response.json())
    .then(nodes => {
      //console.log('n', nodes)
      this.nodes = nodes;
      // this.nodes = nodes.filter(item =>
      //   (urlParams.has('preview') == false &&
      //   (moment().diff(moment.unix(item.created), 'days') < 10
      //   || moment().diff(moment.unix(item.changed), 'days') < 10))
      //   || urlParams.has('preview') == true
      //   //|| (item.field_tv_longue_duree.und !== "undefined" && item.field_tv_longue_duree.und[0] == 1)
      // ); // visible que 5 jours
      //this.nodes = nodes.filter(item => moment().diff(moment.unix(item.created), 'days') < 10); // visible que 5 jours
      //this.nodes = nodes;
        if (this.nodes.length > 0) {
            this.reloadTotal = (this.nodes.length*process.env.nbSecondesBetweenSlide)*2
        }


      this.printNodes()
    });
  }

  printNodes() {
    console.log(this.nodes);
    console.log("nbSecondesBetweenSlide", process.env.nbSecondesBetweenSlide);
    console.log("reloadTotal", this.reloadTotal);
    setTimeout(function(){
      window.location.reload();
   }, this.reloadTotal*1000);

   this.nodes.forEach(function(item) {
    item.texte = item.body.fr[0].summary
    // si il y a un titre tv spécifique, il prend le dessus
    if (item.field_tv_titre.und) {
      item.title = item.field_tv_titre.und[0].value;
    }
    // si il y a un texte tv spécifique, il prend le dessus
    if (item.field_tv_texte.und) {
      item.texte = item.field_tv_texte.und[0].value;
    }

    item.dateCreated = moment.unix(item.created).format('DD MMMM YYYY');
  });
  
  var tpl = `
  ${this.nodes
    .map((node, i) =>
      `
    <div class="swipeindex${i} swiper-slide" data-created="${node.dateCreated}">
    <div class="swipcontent">
     <h1>${node.title}</h1>
     <div class="illusText">
        <div class="text ">
            <div class="truncate">${node.texte}</div>  
            <div class="link"><a href="https://quoi2-9.groupe-telegramme.fr">https://quoi2-9.groupe-telegramme.fr</a></div> 
        </div>
        <div class="illus">
        <img class="" src="${node.field_image_principale_uri}">  
        </div>
     </div>
     
     
      <span class="time" ></span>
    </div>
    </div>
  `.trim()
    )
    .join("")}
`;
//<img class="" src="${node.field_image_principale_uri}">  
  document.querySelector(".swiper-wrapper").innerHTML = tpl;

  var mySwiper = new Swiper(".swiper-container", {
   // Optional parameters
   direction: "horizontal",
   spaceBetween: 30,
   centeredSlides: true,
   autoplay: { // comment dev
     delay: process.env.nbSecondesBetweenSlide*1000,
     disableOnInteraction: false
   },
   on: {
    init: function () {
      document.querySelector('footer .date').innerHTML = document.querySelector('.swipeindex0').dataset.created;
    },
  },
  });
  mySwiper.on('slideChange', function () {
    document.querySelector('footer .date').innerHTML = document.querySelector('.swipeindex'+this.activeIndex).dataset.created;
  });
  // mySwiper.on('init', function () {
  //   alert('dd')
  //   console.log('slide changed', this);
  //   document.querySelector('footer .date').innerHTML = document.querySelector('.swipeindex'+this.activeIndex).dataset.created;
  // });
  }



}

const app = new App()

